.read-more {
  &.read-more-desktop {
    padding: 65px 0px 0px;
    .read-circle {
      position: absolute;
      width: 53px;
      height: 53px;
    }
    .read-flex {
      display: flex;
      .read-img {
        width: 350px;
        max-height: 380px;
        img {
          width: 100%;
        }
      }
      .read-img:not(:last-child) {
        margin: 0px 0px 160px 0px;
      }
      .read-flex__left,
      .read-flex__right {
        flex: 0 0 50%;
      }
    }
  }

  &.read-more-mobile {
    padding: 20px 0;
    margin-bottom: 20px;
    .read-circle {
      width: 38px;
      height: 38px;
    }
    .read-more-item {
      &__title {
        font-size: 16px;
        font-weight: bold;
        line-height: 19 / 16 * 100%;
        margin-bottom: 10px;
      }
    }
    .read-more-flex {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 40px 0 20px 0;
      &__img {
        height: 190px;
        img {
          width: auto;
          max-width: 260px;
          height: 100%;
        }
      }
    }
  }

  li {
    list-style-type: none;
    position: relative;
    padding: 0px 0px 16px 30px;
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 15 / 12 * 100%;

    @include respond-min(sm-desktop) {
      padding: 0px 0px 20px 30px;
      font-size: 14px;
    }
  }
  li::before {
    background: url(../../components/view/careers/openPosition/img/icons/list-dot.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0px;
    top: 0;
    content: "";
  }
  .email {
    cursor: pointer;
    color: $primaryColor;
  }
  .disclaimer {
    font-weight: bold;
  }
  .read-circle {
    background-color: $primaryColor;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -14px;
    left: -27px;
    box-shadow: 0px 32px 64px 0px #aab1cd;
  }
  p:not(:last-child) {
    margin-bottom: 14px;
    @include respond-min(sm-desktop) {
      margin-bottom: 20px;
    }
  }
  .last-p {
    margin-top: 40px;
  }
  .top-text {
    &__title {
      max-width: 310px;
      font-size: 18px;
      font-weight: bold;
      line-height: 22 / 18 * 100%;
      margin: 0px 0px 30px 0px;
      @include respond-min(sm-desktop) {
        max-width: 470px;
        font-size: 24px;
      }
    }
    .read-border__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 29 / 24 * 100%;
      margin: 0px 0px 20px 0px;
      padding: 0 45px;
    }
    .read-border__text {
      font-size: 14px;
      line-height: 18 / 14 * 100%;
      padding: 0 45px;
      max-width: 500px;
    }
    .read-border {
      border-left: 1px solid $primaryColor;
      padding: 0px 0px 1px 0px;
      margin: 65px 0px 0px 0px;
    }
    .read-border-item {
      margin: 0px 0px 40px 0px;
      padding-left: 45px;
      position: relative;

      &__title {
        font-size: 16px;
        font-weight: bold;
        line-height: 19 / 16 * 100%;
        margin: 0px 0px 20px 0px;
      }
      &__text {
        font-size: 14px;
        line-height: 18 / 14 * 100%;
      }
    }
  }
}
