.hexagons-section {
  min-height: 1314px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 480px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  @include respond-min(landscape) {
    max-width: unset;
    min-height: 930px;
  }

  .hex-shadow {
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.12));
    position: absolute;
    left: 50%;
    .hex {
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      & > *:not(:last-child) {
        margin-bottom: 8px;
      }
      &__icon {
        font-size: 40px;
        margin-top: -30px;
      }
      &__title {
        max-width: 158px;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 18 / 14 * 100%;
      }
      &__text {
        font-size: 1.2rem;
        line-height: 15 / 12 * 100%;
        width: 75%;
      }
      &__subtext {
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
      }
    }
  }

  .hex-container-1 {
    top: 50px;
    transform: translateX(-85%);
    .hex {
      height: 230px;
      width: 265px;
      i {
        font-size: 55px;
      }
      .hex__title {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  .hex-container-2 {
    top: 147px;
    transform: translateX(-13%);
    z-index: 3;
    .hex {
      i {
        font-size: 40px;
      }
      height: 220px;
      width: 254px;
    }
  }
  .hex-container-3 {
    top: 281px;
    transform: translateX(-75%);
    z-index: 3;
    .hex {
      height: 240px;
      width: 277px;
      background-color: $primaryColor;
      color: white;
    }
  }
  .hex-container-4 {
    top: 470px;
    transform: translateX(-22%);
    z-index: 2;
    .hex {
      height: 235px;
      width: 270px;
    }
  }
  .hex-container-5 {
    top: 649px;
    transform: translateX(-67%);
    z-index: 1;
    .hex {
      height: 260px;
      width: 300px;
      background-color: #d2d2d2;
    }
  }
  .hex-container-6 {
    top: 840px;
    transform: translateX(-18%);
    .hex {
      i {
        font-size: 32px;
      }
      height: 230px;
      width: 270px;
      background-color: $primaryColor;
      color: white;
    }
  }
  .hex-container-7 {
    top: 1024px;
    transform: translateX(-61%);
    z-index: 1;
    .hex {
      height: 270px;
      width: 310px;
    }
  }

  .background-hex {
    position: absolute;
    left: 50%;

    &-back,
    &-front {
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    &-back {
      height: 270px;
      width: 310px;
      background-color: #c2d0ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-front {
      height: 268px;
      width: 308px;
      background-color: #fff;
    }
  }
  .bg-hex-0 {
    top: 17px;
    transform: translateX(50%);
  }
  .bg-hex-1 {
    top: 361px;
    transform: translateX(-110%);
  }
  .bg-hex-2 {
    top: 705px;
    transform: translateX(27%);
  }
  .bg-hex-3 {
    top: 770px;
    transform: translateX(-112%);
    .background-hex-back {
      height: 220px;
      width: 254px;
    }
    .background-hex-front {
      height: 218px;
      width: 252px;
    }
  }
  .bg-hex-4 {
    top: 615px;
    transform: translateX(-62%);
    z-index: -1;
    .background-hex-back {
      height: 640px;
      width: 739px;
    }
    .background-hex-front {
      height: 638px;
      width: 737px;
    }
  }

  @include respond-min(landscape) {
    .hex-container-1 {
      top: 50px;
      transform: translateX(-50%);
    }
    .hex-container-2 {
      top: 190px;
      transform: translateX(26%);
    }
    .hex-container-3 {
      top: 190px;
      transform: translateX(-122%);
    }
    .hex-container-4 {
      top: 441px;
      transform: translateX(-141%);
    }
    .hex-container-5 {
      top: 346px;
      transform: translateX(-48%);
    }
    .hex-container-6 {
      top: 560px;
      transform: translateX(19%);
    }
    .hex-container-7 {
      top: 630px;
      transform: translateX(-70%);
    }

    .bg-hex-0 {
      top: 107px;
      transform: translateX(-77%);
    }
    .bg-hex-1 {
      top: 303px;
      transform: translateX(-113%);
    }
    .bg-hex-2 {
      top: 140px;
      transform: translateX(-10%);
      .background-hex-back {
        align-items: flex-end;
        justify-content: flex-start;
      }
      .background-hex-front {
        height: 269px;
        width: 309px;
      }
    }
    .bg-hex-3 {
      display: none;
    }
    .bg-hex-4 {
      top: 490px;
      transform: translateX(-30%);
      .background-hex-back {
        height: 360px;
        width: 415px;
      }
      .background-hex-front {
        height: 358px;
        width: 413px;
      }
    }
  }
}
