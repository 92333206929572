.position-item {
  clip-path: polygon(4.6% 0, 95.4% 0, 100% 50%, 95.4% 100%, 4.6% 100%, 0% 50%);
  background-color: white;
  height: 82px;
  width: 420px;
  margin: 6px 0;
  display: flex;
  align-items: center;
  transform: translateX(-60px);
  position: relative;
  opacity: 0.8;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
  &_active {
    opacity: 1;
  }
  @include respond-min(landscape) {
    clip-path: polygon(
      3.86% 0,
      96.135% 0,
      100% 50%,
      96.135% 100%,
      3.86% 100%,
      0% 50%
    );
    width: 620px;
    &.position-item_active {
      background-color: #486ef7;
    }
    cursor: pointer;
  }
  @include respond-min(sm-desktop) {
    clip-path: polygon(
      5.98% 0,
      94.01% 0,
      100% 50%,
      94.01% 100%,
      5.98% 100%,
      0% 50%
    );
    width: 50vw;
    max-width: 612px;
    height: 116px;
  }
  .clip_active {
    clip-path: polygon(
      4.6% 0,
      95.4% 0,
      100% 50%,
      95.4% 100%,
      4.6% 100%,
      0% 50%
    );
    background-color: white;
    height: 78px;
    width: 100%;
    transform: translateX(-2px);
    @include respond-min(landscape) {
      clip-path: polygon(
        3.7% 0,
        96.3% 0,
        100% 50%,
        96.3% 100%,
        3.7% 100%,
        0% 50%
      );
    }
    @include respond-min(sm-desktop) {
      clip-path: polygon(
        5.9% 0,
        94.1% 0,
        100% 50%,
        94.1% 100%,
        5.9% 100%,
        0% 50%
      );
      height: 110px;
      width: 100%;
    }
  }
  &-img {
    position: absolute;
    left: -67px;
    height: 126px;
    filter: grayscale(20%);
    -webkit-transition: filter 240ms;
    -moz-transition: filter 240ms;
    -o-transition: filter 240ms;
    transition: filter 240ms;

    &_active {
      filter: grayscale(0);
    }
    img {
      width: 100%;
      height: 100%;
    }
    @include respond-min(sm-desktop) {
      height: 178px;
      left: -50px;
    }
  }
  .position-icon {
    position: absolute;
    top: 20%;
    left: 0;
    transform: translate(72%, 15%);
    height: 39px;
    width: 39px;
    @include respond-min(sm-desktop) {
      height: 55px;
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(73%, 13%);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .position-text {
    margin-left: 20px;
    max-width: 240px;
    position: absolute;
    top: 50%;
    left: 27%;
    transform: translate(0%, -50%);

    &__level {
      font-size: 14px;
      line-height: 15 / 12 * 100%;
      text-transform: capitalize;
      color: black;
      -webkit-transition: color 0.4s;
      -moz-transition: color 0.4s;
      -o-transition: color 0.4s;
      transition: color 0.4s;
    }
    &_active {
      color: #4a71fd;
    }
    &__name {
      color: $secondaryColor;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
    }
    @include respond-min(landscape) {
      left: 20%;
    }
    @include respond-min(sm-desktop) {
      left: 30%;
      max-width: 350px;
      &__level {
        font-size: 20px;
      }
      &__name {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}
.clip-shadow {
  filter: drop-shadow(0px 0px 37px rgba(0, 0, 0, 0.12));
}
