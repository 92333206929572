:root {
  font-size: 0.625em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ::after,
  ::before {
    box-sizing: border-box;
  }
  -webkit-tap-highlight-color: transparent;
}

.container {
  max-width: 1110px;
  padding: 0 1.6rem;
  margin: 0 auto;
}
.container-lg {
  max-width: 1280px;
  padding: 0 2rem;
  margin: 0 auto;
}
.App {
  margin: 0 auto;
  width: 100%;
}
.section-title {
  color: $primaryColor;
  font-weight: bold;
  line-height: 18 / 14 * 100%;
  font-size: 2.4rem;
  @include respond-max(sm-desktop) {
    font-size: 1.4rem;
  }
}
.subtext {
  font-size: 1.6rem;
  letter-spacing: 0.8px;
  line-height: 2rem;
  @include respond-max(sm-desktop) {
    font-size: 1.4rem;
  }
}
.btn {
  height: 44px;
  background: $primaryColor;
  border-radius: 1.6rem;
  color: #ffffff;
  font-weight: bold;
  padding: 0.7em 2.4em;
  font-size: 1.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: background 0.15s ease;
  &:hover {
    background: $hoverColor;
  }
}
a {
  color: white;
}
img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
p {
  font-size: calc(1.2rem + ((1vw - 3.75px) * 0.3756));
  @include respond-min(sm-desktop) {
    font-size: 1.6rem;
  }
}
// @media (min-width: 320px) {
//   .section-title {
//     font-size: calc(1rem + ((1vw - 3.75px) * 1.3146));
//     min-height: 0vw;
//   }
// }
// @media (min-width: 1024px) {
//   .section-title {
//     font-size: 30px;
//   }
// }
// @media (min-width: 320px) {
//   p {
//     font-size: calc(0.75rem + ((1vw - 3.75px) * 0.3756));
//     min-height: 0vw;
//   }
// }
// @media (min-width: 1024px) {
//   p {
//     font-size: 16px;
//   }
// }

h1 {
  font-size: 3.6rem;
  line-height: 40 / 33 * 100%;
  font-weight: bolder;
  @media (max-width: 640px) {
    font-size: calc(1.8rem + ((1vw - 3.75px) * 2.7735));
  }
  @media (max-width: 376px) {
    font-size: 2.2rem;
  }
}

h2 {
  font-size: 2.8rem;
  font-weight: bolder;
  line-height: 26 / 22 * 100%;
  @media (max-width: 640px) {
    font-size: calc(1rem + ((1vw - 3.75px) * 2.7735));
    min-height: 0vw;
  }
  @media (max-width: 376px) {
    font-size: 22px;
  }
}
