.our-team {
  margin-bottom: 30px;
  @include respond-min(sm-desktop) {
    position: relative;
    margin-top: 160px;
  }
  &__bg {
    position: absolute;
    top: -55%;
    z-index: -1;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(173, 192, 255, 0.4) 100%
    );
    height: 100%;
    width: 100%;
    @include respond-min(sm-desktop) {
      left: 0;
    }
  }
  &-container {
    display: contents;
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include respond-min(sm-desktop) {
      flex-direction: row-reverse;
      justify-content: center;
    }
  }
  &-badges {
    display: flex;
    justify-content: center;
    position: relative;
    @include respond-min(sm-desktop) {
      position: initial;
    }
  }
  &__badge {
    flex: 1 0 33%;
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  &-text {
    padding: 0 20px;
    max-width: 570px;
    margin: 0 auto;
    @media (min-width: 540px) {
      text-align: center;
    }
    @include respond-min(sm-desktop) {
      text-align: left;
      margin: unset;
      p {
        max-width: 440px;
      }
    }
    @media (min-width: 1150px) {
      margin-top: 35px;
    }
  }
  &__title {
    margin: 20px 0 10px;
    @include respond-min(landscape) {
      margin-top: 40px;
    }
    @include respond-min(sm-desktop) {
      margin-bottom: 35px;
    }
  }
}
