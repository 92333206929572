.open-position-flex.container-lg {
  display: contents;
  @include respond-min(sm-desktop) {
    display: flex;
  }
}
.open-position-mobile-text {
  @include respond-min(sm-desktop) {
    display: none;
  }
}
.open-position-desktop {
  display: none;
  @include respond-min(sm-desktop) {
    display: block;
  }
  padding-top: 88px;
  .open-position-dropdowns {
    margin-top: 32px;
    max-height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #4a71fe;
    }
    &::-webkit-scrollbar-thumb:hover {
      outline: 1px solid slategrey;
    }
  }
}
.apply-btn {
  height: 40px;
  width: 160px;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 18px;
  white-space: nowrap;
  margin-top: 16px;
  @include respond-min(sm-desktop) {
    height: 44px;
    width: 160px;
  }
}
.open-position {
  padding: 0 16px;
  margin-top: 220px;
  margin-bottom: 140px;
  @include respond-min(sm-desktop) {
    margin-top: 0;
    margin-bottom: 200px;
  }

  .open-position-items {
    margin-left: 65px;
  }
  &__title {
    margin: 0px 26px 20px;
    font-size: 18px;
    @include respond-min(sm-desktop) {
      font-size: 24px;
      letter-spacing: 1.2px;
      line-height: 29px;
    }
  }

  &-text {
    margin-bottom: 20px;
    margin-left: -32px;
    @include respond-min(sm-desktop) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-description {
    font-size: 1.4rem;
    color: $secondaryColor;
    margin: 20px 0;
    @include respond-min(sm-desktop) {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
}
