.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid $primaryColor;
  &__logo {
    margin: 14px 0;
    height: 44px;
    width: 100px;
    img {
      width: 100%;
    }
  }
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(246, 247, 255, 0.75);
    backdrop-filter: blur(10px);
  }
  > .container {
    position: relative;
    z-index: 5;
  }
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  margin: 0 auto;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
}

.nav-menu a {
  font-size: 16px;
  line-height: 19 / 16 * 100%;
  color: $primaryColor;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.5s;
  &.active {
    font-weight: bold;
    position: relative;
    &:after {
      content: "";
      display: block;
      height: 8px;
      width: 31px;
      border-radius: 4px 4px 0 0;
      background-color: $primaryColor;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      z-index: 2;
      @media screen and (max-width: 680px) {
        display: none;
      }
    }
  }
}
.menu__link {
  display: block;
  cursor: pointer;
  padding: 26px 30px;
  transition: 0.5s;
  @include respond-max(sm-desktop) {
    padding: 26px 20px;
  }
}
.burger {
  display: none;
  cursor: pointer;
  &.active div {
    background-color: white;
  }
}

.burger div {
  width: 30px;
  height: 3px;
  margin: 5px;
  background: $primaryColor;
  transition: all 240ms ease-in;
}

.burger.active .line1 {
  transform: rotate(45deg) translate(5px, 6px);
}

.burger.active .line2 {
  opacity: 0;
}
.burger.active .line3 {
  transform: rotate(-45deg) translate(5px, -6px);
}

@media screen and (max-width: 680px) {
  .burger {
    display: block;
  }

  .nav-menu {
    background: rgb(101, 177, 246);
    background: linear-gradient(
      180deg,
      rgba(101, 177, 246, 1) 0%,
      rgba(109, 67, 220, 1) 100%
    );
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    padding: 56px;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: translate3d(120%, 0, 0);
    transition: transform 240ms;
    a {
      margin: 25px;
      font-size: 22px;
    }
  }

  .nav-menu li {
    opacity: 0;
  }

  .nav-menu.active li,
  .nav-menu li {
    animation: navLinksFade 0.7s ease forwards 1s;
  }
}

.nav-menu.active {
  transform: inherit;
  a {
    background: none;
  }
  .menu__link {
    color: white;
    padding: 0;
    margin: 24px 0;
  }
}

@media screen and (max-width: 375px) {
  .header {
    &__logo {
      margin: 12px 0;
      height: 37px;
      width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav {
    height: 55px;
  }
}

@keyframes navLinksFade {
  0% {
    opacity: 0;
    transform: translateY(50px) translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}
