.banner {
  max-width: 1610px;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
  margin-bottom: 100px;
  @media (min-width: 840px) {
    margin-bottom: 350px;
  }
  a {
    color: white;
  }
  &__img {
    position: absolute;
    top: 20px;
    right: 0;
  }

  .blue-mask {
    background: url(../../components/img/mask.svg) no-repeat;
    width: 100%;
    height: 402px;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: -1;
    @media (min-width: 830px) {
      height: 700px;
    }
    background-position: left calc(50vw + 20%) bottom 3%;
    @include respond-min(landscape) {
      background-position: left calc(50vw + 50%) bottom 3%;
    }
    @media (min-width: 840px) {
      background-position: left 25vw bottom -85%;
    }
  }
  &-content {
    margin-top: 10px;
    @include respond-min(landscape) {
      margin-top: 25px;
    }
    @include respond-min(sm-desktop) {
      margin-top: 100px;
    }
    > *:not(:last-child) {
      margin-bottom: 14px;
      @include respond-min(sm-desktop) {
        margin-bottom: 24px;
      }
    }
  }

  &__title {
    font-weight: 900 !important;
    white-space: pre-line;
    letter-spacing: 0.9px;
    line-height: 22 / 10 * 100%;
    font-weight: 900;
  }
  &__subtext {
    color: $secondaryColor;
    max-width: 400px;
    @include respond-min(sm-desktop) {
      max-width: 480px;
    }
  }
  &__btn {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: 18 / 14 * 100%;
    @include respond-min(sm-desktop) {
      font-size: 1.6rem;
      margin-top: 12px;
    }
  }
}

.home-banner {
  margin-bottom: 140px;
  .banner__img {
    width: 240px;
    top: 60%;
    @media (min-width: 540px) {
      width: 365px;
      top: 35%;
    }
    @media (min-width: 830px) {
      width: 570px;
      top: 20%;
    }
    @include respond-min(sm-desktop) {
      right: unset;
      left: calc(20vw + 23%);
    }
    img {
      width: 100%;
    }
  }
}

.technology-banner {
  .banner__img {
    width: 175px;
    top: 100%;
    right: 7%;
    @media (min-width: 540px) {
      width: 270px;
      top: 40%;
    }
    @media (min-width: 840px) {
      width: 340px;
      top: 25%;
      right: 15%;
    }
    @include respond-min(sm-desktop) {
      width: 360px;
      right: unset;
      left: calc(20vw + 23%);
    }
    img {
      width: 100%;
    }
  }
  .banner__subtext {
    font-size: 14px;
    @media (min-width: 540px) {
      max-width: 380px;
    }
    @include respond-min(sm-desktop) {
      font-size: 20px;
      max-width: 480px;
    }
  }
}

.careers-banner {
  .banner__img {
    width: 300px;
    top: 50%;
    right: 5%;
    @media (min-width: 540px) {
      width: 400px;
      top: 25%;
    }
    @media (min-width: 840px) {
      width: 500px;
      right: 15%;
    }
    @include respond-min(sm-desktop) {
      width: 580px;
      right: unset;
      left: calc(20vw + 20%);
    }
    img {
      width: 100%;
    }
  }
  .banner__title {
    max-width: 270px;
    @media (min-width: 638px) {
      max-width: 390px;
    }
    @include respond-min(sm-desktop) {
      max-width: 480px;
    }
  }
}
