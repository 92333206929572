.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  transform: scale(0.9);
  @media (min-width: 375px) {
    transform: unset;
  }
  @include respond-min(landscape) {
    flex-direction: row;
    margin-top: -60px;
  }
  @include respond-min(sm-desktop) {
    margin-top: unset;
    margin-bottom: -100px;
  }
  .chart-text {
    width: 157px;
    height: 175px;
    margin: -50px auto 0;
    @include respond-min(landscape) {
      margin: unset;
      margin-left: 60px;
    }
    @include respond-min(sm-desktop) {
      width: 240px;
      height: 255px;
      margin-left: 120px;
    }
    &__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-left: -18px;
      margin-bottom: 16px;
      @include respond-min(sm-desktop) {
        font-size: 34px;
        line-height: 42px;
      }
    }

    &__body {
      li {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
        @include respond-min(sm-desktop) {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
