.applying {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin-top: -80px;
  margin-bottom: 60px;

  @include respond-min(landscape) {
    margin-bottom: 45px;
  }
  @include respond-min(sm-desktop) {
    margin-bottom: 50px;
  }
  > *:not(:last-child) {
    margin-bottom: 28px;
  }
  &__img {
    width: 300px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__text {
    max-width: 300px;
    color: $primaryColor;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    line-height: 22px;
    @include respond-min(landscape) {
      max-width: 390px;
    }
    @include respond-min(desktop) {
      max-width: unset;
    }
  }
  &__btn {
    width: 140px;
    white-space: nowrap;
    text-transform: capitalize;
  }
  a {
    color: white;
  }
}
