.dropdown {
  margin: 16px 0;
  &__header {
    
    display: flex;
    align-items: center;
    @include respond-min(sm-desktop) {
      cursor: pointer;
    }
    .dropdown-title {
      color: $secondaryColor;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.9px;
      line-height: 22px;
      margin-left: 20px;
    }

    .dropdown-icon {
      position: relative;
      z-index: 1;
      padding: 1rem;
      margin: -1rem;
      display: flex;
      align-items: center;
      img {
        transition: transform 240ms ease;
      }
    }
    .dropdown-icon.rotate {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown-list {
    width: 90%;
    margin: 25px 0 20px;
    display: none;
  }
  .dropdown-list.show {
    display: block;
  }
  .dropdown-list li {
    list-style-type: none;
    position: relative;
    font-size: 14px;
    color: $secondaryColor;
    letter-spacing: 0.6px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-left: 26px;
    &:not(:last-child) {
      padding-bottom: 26px;
    }
    @include respond-min(landscape) {
      font-size: 16px;
      line-height: 20px;
    }
    @include respond-min(sm-desktop) {
      font-size: 18px;
      letter-spacing: 0.9px;
      line-height: 22px;
    }
  }
  .dropdown-list li::before {
    background: url(../../components/view/careers/openPosition/img/icons/list-dot.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
  }
}
