.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.applying-modal {
  padding: 24px 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 760px;
  height: 470px;
  background-color: #fff;
  z-index: 1000;

  @include respond-max(sm-desktop) {
    width: 680px;
  }
  @media (max-width: 740px) {
    width: 90%;
    height: 80%;
  }
  @include respond-max(landscape) {
    padding: 16px 20px;
  }
  .modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    &__title {
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 28 / 24 * 100%;
      margin-bottom: 55px;
      @media (max-width: 740px) {
        margin-bottom: 16px;
      }
    }
  }
  .modal-form {
    .inputs {
      display: flex;
      flex-wrap: wrap;

      > div {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        color: #9b9b9b;
        font-size: 16px;
        font-weight: bold;
        line-height: 19 / 16 * 100%;
        margin: 12px 0;
        &:first-child {
          margin-right: 16px;
        }
        @media (max-width: 740px) {
          flex: 1 0 100%;
        }
      }
      label {
        margin-bottom: 8px;
      }
      input {
        height: 48px;
        border: 1px solid #a9a9bd;
        background-color: #ffffff;
        font-size: 20px;
        padding: 8px;
        @include respond-max(mobile) {
          height: 40px;
        }
      }
      input:focus {
        outline: 1px solid #a9a9bd;
      }
    }
  }
  .upload-btn,
  .modal-applying-btn {
    height: 48px;
    width: 166px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: 16 / 14 * 100%;
  }
  .upload {
    input[type="file"] {
      display: none;
    }
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond-max(landscape) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-text {
      white-space: nowrap;
      color: #211228;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 19 / 16 * 100%;
    }
    &-actions {
      flex: 0 0 50%;
      display: flex;
      justify-content: space-between;
      @include respond-max(landscape) {
        flex-direction: row-reverse;
      }
    }
    &-label {
      white-space: nowrap;
      color: #a9a9bf;
      font-size: 16px;
      line-height: 19 / 16 * 100%;
      transform: translate(0%, 33%);
      @include respond-max(landscape) {
        transform: translate(15%, 45%);
      }
      @include respond-max(mobile) {
        white-space: unset;
      }
    }
    &-btn {
      color: #1f1327;
      margin-left: 24px;
      border: 1px solid #9b29f5;
      transition: box-shadow 240ms;
      &:hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
      }
      @include respond-max(landscape) {
        margin-top: 16px;
        margin-left: unset;
      }
      @include respond-max(mobile) {
        height: 48px;
        width: 138px;
      }
    }
  }
  .error {
    color: #d32f2f;
  }
  .modal-applying-btn {
    margin: 40px auto;
    font-family: Roboto;
    border: 1px solid #9b29f5;
    background-color: #9b29f5;
    color: white;

    transition: transform 240ms;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    }
    @include respond-max(landscape) {
      margin: 16px auto;
      width: 100%;
    }
  }
  .close {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
  }
  .close:before,
  .close:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 3px;
    height: 26px;
    width: 3px;
    background-color: #1f1327;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
