@font-face {
  font-family: "icons";
  src: url("./font-icons/icons.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"],
[class*=" _icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-book-alt:before {
  content: "\e900";
  color: #fff;
}
._icon-brain:before {
  content: "\e901";
  color: #fff;
}
._icon-clipboard-notes:before {
  content: "\e902";
  color: #4b72ff;
}
._icon-comments-alt:before {
  content: "\e903";
  color: #4b72ff;
}
._icon-heart:before {
  content: "\e904";
  color: #4b72ff;
}
._icon-map-marker-alt:before {
  content: "\e905";
  color: #4b72ff;
}
._icon-phone:before {
  content: "\e906";
  color: #4b72ff;
}
._icon-trophy:before {
  content: "\e907";
  color: #4b72ff;
}
._icon-user-check:before {
  content: "\e908";
  color: #4b72ff;
}
._icon-venus:before {
  content: "\e909";
  color: #4b72ff;
}
._icon-wifi:before {
  content: "\e90a";
  color: #4b72ff;
}
