.what-we-build-item {
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  .section-inner {
    background-color: #f8f8f8;
    min-height: 210px;
    position: relative;
    padding: 0px 20px 50px;
    .section-img {
      width: 270px;
      position: absolute;
      transform: translate(-50%, -49%);
      left: 50%;
      .slogan {
        white-space: pre-line;
        text-align: center;
        position: inherit;
        top: 68%;
        left: 50%;
        transform: translate(-50%, 0%);
        color: $primaryColor;
        font-size: 12px;
        font-style: italic;
        line-height: 18px;
      }
      img {
        width: 100%;
        height: auto;
      }
      @include respond-min(landscape) {
        width: 36%;
        max-width: 330px;
        left: -40px;
        transform: translate(40%, -49%);
      }
      @include respond-min(desktop) {
        left: unset;
        right: 20%;
      }
    }
    @include respond-min(desktop) {
      background-color: white;
    }
  }
  .section-white {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &__text {
      width: 50%;
      margin-left: auto;
      padding-right: 16px;
      font-size: 14px;
      > * {
        margin: 0px 0px 20px 0px;
      }
      @include respond-min(desktop) {
        max-width: 500px;
      }
    }
    @include respond-min(desktop) {
      background-color: #f8f8f8;
      padding: 50px 0 0 0;
    }
  }
  .above-description {
    display: none;
    font-size: 16px;
    @include respond-min(landscape) {
      display: block;
    }
    @include respond-min(desktop) {
      font-size: 20px;
    }
  }
  .above-subtext {
    display: none;
    @include respond-min(desktop) {
      display: block;
    }
  }
  .below-description {
    @include respond-min(landscape) {
      display: none;
    }
  }
  .below-subtext {
    @include respond-min(desktop) {
      display: none;
    }
  }
  .above-subtext,
  .below-subtext {
    color: $primaryColor;
    font-size: 20px;
    line-height: 24 / 20 * 100%;
  }

  .section-content {
    text-align: center;
    margin-top: 136px;
    @include respond-min(landscape) {
      text-align: left;
      width: 50%;
      margin: 0px 0px 0px auto;
    }
    @include respond-min(desktop) {
      margin: 0 auto;
      text-align: right;
      transform: translateX(-50px);
    }
    &__text {
      .section-description,
      .above-description {
        max-width: 310px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 18 / 14 * 100%;
      }
      .section-subtext {
        margin: 20px auto;
        @include respond-min(landscape) {
          margin: 20px 0px 0px 0px;
        }
      }
    }

    &__icons {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      .icons-text {
        display: none;
        @include respond-min(desktop) {
          display: block;
          font-size: 20px;
          line-height: 24 / 20 * 100%;
          width: 180px;
          text-align: right;
          margin: 35px 5px 0px 0px;
        }
      }
      .icons-icon {
        width: 83px;
        height: 72px;
        @include respond-min(desktop) {
          width: unset;
        }
        img {
          width: auto;
          height: auto;
          object-fit: none;
        }
      }
      .section-icon {
        display: flex;
        align-items: center;
        @include respond-min(desktop) {
          width: 308px;
          justify-content: space-between;
          margin: 0px 0px 0px 20px;
        }
      }
    }

    .max-width-sm {
      max-width: 330px;
      transform: translateX(20px);
    }
    .max-width-lg {
      max-width: 375px;
      transform: translateX(-18px);
      justify-content: center;
      @include respond-min(landscape) {
        justify-content: flex-start;
      }
    }
    .max-width-sm,
    .max-width-lg {
      @include respond-min(landscape) {
        max-width: 345px;
        transform: translateX(-35px);
        margin: 0;
      }
      @include respond-min(desktop) {
        max-width: 700px;
        transform: translateX(-11%);
        justify-content: space-between;
        margin: 0 auto;
      }
    }
  }
}
