.perks {
  margin-top: 18px;
  margin-bottom: 20px;
  @include respond-min(sm-desktop) {
    margin-left: 120px;
    margin-bottom: 120px;
  }
  &__title {
    display: none;
    @include respond-min(sm-desktop) {
      display: block;
      margin: 60px 0px 50px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__item {
      display: flex;
      align-items: center;
      background-color: #f4f4f4;
      height: 72px;
      position: relative;
      left: 0;
      margin-left: 90px;
      width: 337px;
      transition: all 240ms;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:after {
        content: "";
        position: absolute;
        right: -72px;
        width: 72px;
        height: 72px;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        border-left: 20px solid #f4f4f4;
      }
      @include respond-min(sm-desktop) {
        width: 100%;
        &:hover {
          transform: translateX(4px);
          box-shadow: 3px 2px 8px 0px rgba(0, 0, 0, 0.34);
        }
      }
    }
    .perks-img {
      position: absolute;
      left: -20%;
      top: -20px;
      @include respond-min(sm-desktop) {
        left: -61px;
      }
    }
    .perks-text {
      max-width: 225px;
      margin-left: 60px;
      color: $primaryColor;
      font-size: 12px;
      font-weight: bold;
      line-height: 15 / 12 * 100%;
    }
  }
}
