.pie-chart {
  margin: -80px auto 0;
  display: flex;
  justify-content: center;
  max-width: 330px;
  @include respond-min(landscape) {
    margin: unset;
  }
  @include respond-min(sm-desktop) {
    max-width: unset;
  }
  .segment {
    color: #979797;
    display: none;
  }

  path {
    transition: fill 240ms;
  }
}
