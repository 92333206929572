.tech-stack {
  margin-top: 200px;
  background: url(../../components/view/technology/img/tech-stack-bg.svg)
    no-repeat center;
  // background-size: ;
  height: 570px;
  display: flex;
  align-items: flex-end;
  @include respond-min(sm-desktop) {
    height: 716px;
  }
  &__text {
    text-align: center;
    margin-top: -16px;
    color: #4a4a4a;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 18 / 14 * 100%;
    @include respond-min(sm-desktop) {
      max-width: 250px;
      text-align: left;
    }
  }
  .tech-icons {
    max-width: 782px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 460px) {
      margin-bottom: 100px;
    }
    @media (min-width: 710px) {
      margin-bottom: 180px;
    }
    @include respond-min(sm-desktop) {
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      margin-bottom: unset;
    }
  }
  &-item {
    max-width: 117px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-min(sm-desktop) {
      flex-direction: row;
      max-width: unset;
      margin-top: -44px;
    }
  }
  &__img {
    @include respond-min(sm-desktop) {
      width: 118px;
      img {
        width: 100%;
      }
    }
  }
  &__left,
  &__right {
    display: contents;
    @include respond-min(sm-desktop) {
      display: block;
    }
  }
  &__left {
    transform: translate(-60px, -124px);
  }
  &__right {
    transform: translate(156px, -124px);
  }
}
