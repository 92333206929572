.what-we {
  @include respond-min(sm-desktop) {
    margin-bottom: 120px;
  }

  &__header {
    margin: 40px 0px -50px;
    text-align: center;
    @include respond-min(landscape) {
      font-size: 22px;
    }
    @include respond-min(sm-desktop) {
      font-size: 30px;
      margin-bottom: 50px;
    }
  }
}
