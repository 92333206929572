.about-us {
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    margin-top: 160px;
  }
  @media (min-width: 630px) {
    margin-bottom: -170px;
  }
  @media (min-width: 730px) {
    margin-bottom: 0px;
    margin-top: 350px;
  }

  &-title {
    max-width: 422px;
    margin: 0 16px;
    @media (min-width: 730px) {
      width: 367px;
      margin: 0 auto 20px;
    }
  }
  .container-1,
  .container-2 {
    display: flex;
    align-items: center;
    @media (min-width: 730px) {
      width: 400px;
      margin: 0 auto;
    }
  }
  .container-2 {
    flex-direction: row-reverse;
    transform: translateY(-25%);
    @include respond-min(mobile) {
      transform: translateY(-30%);
    }
    @media (min-width: 630px) {
      transform: translateY(-60%);
    }
    @media (min-width: 730px) {
      transform: unset;
      flex-direction: row;
      margin-top: -10px;
    }
  }

  .left-img,
  .right-img {
    max-width: 225px;
    @media (min-width: 730px) {
      position: absolute;
      max-width: 400px;
      width: 33%;
    }
    img {
      width: 100%;
    }
  }
  .left-img {
    top: -70%;
    left: 0px;
  }
  .right-img {
    top: 0px;
    right: 0px;
  }

  .p1 {
  }
  .p2 {
    text-align: right;
    @media (min-width: 630px) {
      text-align: left;
    }
  }
  .p1,
  .p2 {
    max-width: 195px;
    margin: 16px;
    font-size: 12px;
    line-height: 15 / 12 * 100%;
    @media (min-width: 730px) {
      max-width: unset;
      font-size: 16px;
    }
  }
}
