.hex-desktop {
  margin-bottom: -270px;
  .wrapper {
    padding: 190px 0 20px;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .shadow,
  .shadow-bg {
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.12));
  }

  .hex {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    height: 340px;
    width: 390px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    .hex-content > *:not(:last-child) {
      margin-bottom: 20px;
    }
    .hex-content {
      display: flex;
      flex-direction: column;

      width: 250px;
      margin: 0 auto;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    &__icon {
      font-size: 50px;
      margin-top: 20px;
    }
    &__title {
      font-weight: bold;
    }
    &__subtext {
      font-weight: bold;
    }
  }
  .col-1 {
    position: relative;
  }
  .col-1,
  .col-3 {
    transform: translateX(-12.5%);
  }
  .col-2 {
    transform: translate(12.5%, 16.5%);
  }
  .col-1 {
    transform: translateX(37%);
  }
  .col-4 {
    transform: translate(-37.5%, -16.5%);
    position: relative;
  }
  .shadow {
    transition: transform 240ms;
  }
  .hex-2 {
    .hex {
      background-color: $primaryColor;
      color: #ffffff;
    }
  }
  .hex-2,
  .hex-3,
  .hex-6 {
    .hex__title {
      width: 230px;
      margin: 0 auto;
    }
  }
  .hex-1 {
    .hex__title {
      font-size: 3.4rem;
      line-height: 4.2rem;
      white-space: pre-line;
    }
  }
  .hex-7 {
    .hex {
      background-color: $primaryColor;
      color: #ffffff;
    }
  }

  .bg-hex {
    position: absolute;
    top: 16.5%;
    right: -75%;
  }
  .col:hover {
    position: relative;
    z-index: 1;
    .shadow:hover {
      transform: scale(1.03);
      position: relative;
      z-index: 2;
    }
  }

  .line1 {
    width: 571px;
    border-bottom: 1px solid #c2d0ff;
    transform: rotate(120deg);
    position: absolute;
    bottom: 60%;
    left: -108%;
  }
  .line2 {
    width: 700px;
    border-bottom: 1px solid #c2d0ff;
    transform: rotate(120deg);
    position: absolute;
    bottom: 36.9%;
    left: -59%;
  }
}
