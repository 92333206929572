.footer {
  background-color: #c4d1ff;
  i {
    font-size: 20px;
  }
  &__container {
    padding-top: 30px;
    @include respond-min(landscape) {
      padding: 30px 30px 20px 30px;
    }
    @include respond-min(sm-desktop) {
      padding-top: 0;
      margin-top: -70px;
    }
    .contact-title {
      text-transform: capitalize;
      font-size: 24px;
      margin-bottom: 20px;
      display: inline-block;
    }
    img {
      margin: 0px 0px 50px 0px;
      @media screen and (max-width: 680px) {
        margin: 0px 0px 30px 0px;
      }
    }
  }
}
.angle {
  display: none;
  @include respond-min(sm-desktop) {
    display: block;
    height: 70px;
    width: 55%;
    background-color: #c4d1ff;
    transform: skew(30deg) translate(-7%, -99%);
  }
}

.footer__content {
  display: flex;
  flex-direction: column-reverse;
  @include respond-min(sm-desktop) {
    flex-direction: row;
  }
  .contact-form {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 680px) {
      .contact-title {
        margin: 0px;
      }
    }
    textarea::placeholder,
    input::placeholder {
      font-family: "Montserrat";
    }
    input {
      height: 45px;
      padding: 13px;
      background-color: inherit;
      font-size: 16px;
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #9fb4ff;
      transition: box-shadow 240ms;
    }

    ::placeholder {
      color: $secondaryColor;
    }

    :-ms-input-placeholder {
      color: $secondaryColor;
    }

    ::-ms-input-placeholder {
      color: $secondaryColor;
    }
    .message-area {
      background-color: inherit;
      padding: 13px;
      font-size: 16px;
      border: 1px solid #9fb4ff;
      outline: none;
      -webkit-appearance: none;
    }
    textarea::-webkit-input-placeholder {
      color: $secondaryColor;
    }

    textarea:-moz-placeholder {
      color: $secondaryColor;
    }

    textarea::-moz-placeholder {
      color: $secondaryColor;
    }

    textarea:-ms-input-placeholder {
      color: $secondaryColor;
    }

    textarea::placeholder {
      color: $secondaryColor;
    }
    * {
      margin: 20px 0px 0px 0px;
    }
    .contact__btn {
      margin: 20px auto;
      width: 100%;
      max-width: 375px;
      font-weight: bold;
      letter-spacing: 0.7px;
      line-height: 18 / 14 * 100%;
      @include respond-min(sm-desktop) {
        width: 100%;
      }
    }
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: 100%;
    @include respond-min(sm-desktop) {
      flex: 1 0 auto;
      margin-top: 65px;
      margin-left: 90px;
    }
    @include respond-min(desktop) {
      margin-left: 130px;
    }
    .list-item {
      list-style: none;
      display: flex;
      align-items: center;

      > span {
        margin-right: 20px;
        height: 20px;
        width: 20px;
      }
      .list-item__text {
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        @include respond-min(landscape) {
          flex-direction: row;
        }
        .bold {
          @include respond-min(landscape) {
            margin-left: 10px;
          }
        }
        .info {
          white-space: nowrap;
          margin-bottom: 4px;
        }
        .bold {
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
    .list-item:not(:last-child) {
      margin-bottom: 10px;
      @include respond-min(landscape) {
        margin-bottom: 20px;
      }
    }

    .social-media-icons {
      margin: 50px 0px;
      @media screen and (max-width: 680px) {
        margin: 30px 0px 25px 0px;
      }
      img {
        margin: 0px 14px 0px 0px;
      }
    }
  }
}
input:focus,
textarea:focus {
  box-shadow: 0 0 7px rgba(138, 163, 255, 0.8);
}
.git_version {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #9db2ff;
  padding: 8px 0;
}
