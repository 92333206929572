.carousel {
  position: relative;
  z-index: 1;
  @include respond-max(landscape) {
    .slick-slide {
      .item-text {
        animation: fadeOut 0.7s forwards;
      }
    }
    .slick-center {
      // animation: fadeIn 0.4s forwards;
      .item-text {
        animation: fadeIn 0.4s forwards;
      }
    }
  }

  &-header {
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
    @include respond-max(landscape) {
      margin-bottom: 30px;
    }
    @include respond-max(mobile) {
      font-size: 22px;
    }
  }
  &__content {
    background-color: #f4f4f4;
    padding: 30px 0;

    .slick-arrow {
      height: 60px;
      margin: 0 48px;
      z-index: 1;
      @include respond-max(landscape) {
        z-index: -1;
        //display none doesn't work
      }
    }
  }

  &-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    @media (max-width: 924px) {
      flex-direction: column;
    }
    @include respond-max(landscape) {
      margin: 0 60%;
    }
    @include respond-max(mobile) {
      margin: 0 7%;
    }

    &__img {
      width: 40vw;
      max-width: 530px;
      @include respond-max(landscape) {
        width: 260px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .item-text {
    margin: 0px 0px 0px 40px;
    max-width: 400px;
    @include respond-max(mobile) {
      margin: 0 auto;
      max-width: 250px;
    }
    &__title {
      width: 410px;
      margin: 0px 0px 30px 0px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 1.2px;
      line-height: 29 / 24 * 100%;
      @media (max-width: 924px) {
        font-size: 20px;
        margin: 0px 0px 20px -20px;
      }
      @include respond-max(mobile) {
        width: 282px;
        text-align: center;
        font-size: 16px;
      }
    }
    &__list {
      li {
        line-height: 32 / 18 * 100%;
        @media (max-width: 924px) {
          font-size: 16px;
        }
        @include respond-max(mobile) {
          font-size: 14px;
        }
      }
      li {
        margin: 0px 0px 10px 0;
      }
      li:before {
        content: "";
        margin: 0px 0px 0px 13px;
        @include respond-max(landscape) {
          margin: 0px;
        }
      }
    }
  }
  ul {
    .slick-active .hexagon:before {
      background-color: #4b72ff;
    }
  }

  .hexagon {
    position: relative;
    overflow: hidden;
    width: 20px;
    height: 20px;
    transform: rotate(0deg) skewX(30deg) scaleY(0.866);
  }
  .hexagon:before {
    content: "";
    position: absolute;
    right: 5%;
    bottom: 0;
    left: 8%;
    top: 0;
    transform: scaleY(1.155) skewX(-29deg) rotate(30deg);
    background: #17234e;
    color: white;
    font: 1em/10 Verdana, sans-serif;
    text-align: center;
    text-shadow: 0 0 0.5em black;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
